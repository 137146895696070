import { Instance, types, flow } from "mobx-state-tree";
import { Props as PageProps, SearchBarType, SearchBarTypeString, promoTypeMap } from "@/app/s-for-addon/util";
import { fetchPromoInfo } from "@/apis/apiPromotion";
import { withSetPropAction } from "./helpers/withSetPropAction";
import { getUserUUIDFromCookie } from "@/utils/userUtils";

export const SearchForAddonStoreModel = types
  .model("SearchForAddonStore")
  .props({
    promoInfo: types.frozen({}),
    type: types.optional(types.enumeration("SearchBarType", Object.values(SearchBarType)), SearchBarType.Promotion),
    skuId: types.maybe(types.string),
    promotionId: types.maybe(types.string),
    couponId: types.maybe(types.string),
    batchId: types.maybe(types.string),
    coudanTabs: types.frozen([]),
    coudanTabIndex: types.optional(types.number, 0),
    isExpired: types.optional(types.boolean, false), // 券/促是否过期
    hidePromotionBars: types.optional(types.boolean, false), // 是否隐藏促销栏
  })
  .actions(withSetPropAction)
  .actions((self) => ({
    getPromoInfos: flow(function* () {
      const infos = yield fetchPromoInfo({
        cartuuid: getUserUUIDFromCookie(),
        omittedDetail: false,
        verticalTag: "cn_ybxt_b2c",
        userActionId: promoTypeMap[self.type].actionId,
        operations: {
          products: [{ skuId: self.skuId }],
          promotions: [{ id: self.promotionId, promotionId: self.promotionId }],
          coupons: [
            {
              couponId: self.couponId,
              batchId: self.batchId,
            },
          ],
        },
      });
      const mainInfo = infos?.data?.data?.[infos?.data?.hierarchy?.root[0]]?.info[promoTypeMap[self.type as SearchBarTypeString].infoType];
      self.isExpired = (self.type == SearchBarType.Promotion && !mainInfo) || (self.type == SearchBarType.Voucher && !mainInfo?.endTime);
      // 初次渲染 没有促销信息 代表活动已经过期，直接不展示
      self.hidePromotionBars = !Object.keys(self.promoInfo)?.length && self.isExpired;
      // 优惠券缺少结束时间或促销没有返回值时 代表活动已过期 不更新促销信息
      if (!self.hidePromotionBars && (!self.isExpired || !Object.keys(self.promoInfo)?.length)) {
        self.promoInfo = {
          ...mainInfo,
          currencySymbol: infos?.data?.localInfo?.currencySymbol,
        };
        self.coudanTabs = JSON.parse(mainInfo?.coudanTabs || "[]");
        if (JSON.parse(mainInfo?.coudanTabs || "[]")?.length - 1 < self.coudanTabIndex) {
          self.coudanTabIndex = 0;
        }
      }
    }),
    setCoudanTabIndex(index: number) {
      self.coudanTabIndex = index;
    },
  }));
