import { jdiColorFetch } from "@/apis/colorFetchV2";

interface IPromoReq {
  cartuuid: string;
  omittedDetail: boolean;
  userActionId: string;
  verticalTag: string;
  operations: {
    // 券促
    coupons?: { batchId?: string; couponId?: string }[];
    // 总促
    products?: { skuId?: string }[];
    promotions?: {
      id?: string;
      promotionId?: string;
    }[];
  };
}

export const fetchPromoInfo = async ({ cartuuid, omittedDetail = false, userActionId, verticalTag, operations }: IPromoReq) => {
  return await jdiColorFetch("trade_cart_modifyItem", {
    method: "POST",
    bodyQuery: {
      cartuuid,
      omittedDetail,
      verticalTag,
      operations,
      userActionId,
    },
  });
};

export async function queryProductRecommendData(params: { subSceneCode?: string; sceneCode?: string; page?: number; pageSize?: number; bulkPrice?: string }) {
  const { sceneCode, subSceneCode, bulkPrice, page, pageSize } = params;
  const res = await jdiColorFetch(
    `guide_recommendation_get`,
    // cache保存时间5秒
    {
      bodyQuery: {
        sceneCode,
        subSceneCode,
        bulkPrice,
        page,
        pageSize,
      },
      next: { revalidate: 5 },
      method: "post",
    },
  );
  if (res.error) {
    throw new Error(res.error);
  }
  return res;
}
