/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Instance, types } from "mobx-state-tree";

const Helpful = types
    .model({
      helpfulState: types.optional(types.boolean, false),
      helpfulNum: types.optional(types.string, "0")
    })
const Anonymous = types
    .model({
        nickName: types.optional(types.string, ""),
        userImage: types.optional(types.string, ""),
        isAnoymous: types.optional(types.boolean, false)
    })
export const CommentOptsStoreModel = types
  .model("CommentOptsStore")
  .props({
    helpfuls: types.map(Helpful),
    anonymous: types.map(Anonymous)
  })
  .actions((self: any) => ({
    changeHelpState (commentId: string, params: typeof Helpful) {
      self.helpfuls.set(commentId, Helpful.create(params))
    },
      changeAnonymousState (commentId: string, params: typeof Anonymous) {
          self.anonymous.set(commentId, Anonymous.create(params))
      }
  }));

export interface CheckoutStoreModel extends Instance<typeof CommentOptsStoreModel> {}
