import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { CommentOptsStoreModel } from "./CommentOptsStore";
import { SearchForAddonStoreModel } from "./SearchForAddonStore";
import { CmsUkCouponStore } from "./CmsUkCouponStore";

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
  commentOpstsStore: types.optional(CommentOptsStoreModel, {}),
  searchForAddonStore: types.optional(SearchForAddonStoreModel, {}),
  cmsUkCouponStore: types.optional(CmsUkCouponStore, {}),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}
/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
