import _ from "lodash";

export enum SearchBarType {
  Promotion = "0",
  Voucher = "1",
  PostFee = "2",
}

export const promoTypeMap = {
  [SearchBarType.Promotion]: {
    name: "promotion",
    actionId: "getPromotionSummary",
    infoType: "HJM-M#promotionSummary&basic",
    detailText: "promotionDetailText",
  },
  [SearchBarType.Voucher]: {
    name: "voucher",
    actionId: "getCouponSummary",
    infoType: "HJM-M#couponSummary&basic",
    detailText: "couponDetailText",
  },
  [SearchBarType.PostFee]: {
    name: "postFee",
    actionId: "getFreightSummary",
    infoType: "HJM-M#basic&basic",
    detailText: "detailText",
  },
};

export enum EPromotionType {
  CNT = 6, // 计价促销
  PRICE = 11, // 计件促销
}

export enum SortType {
  sort_default = "sort_default",
  sort_dredisprice_asc = "sort_dredisprice_asc",
  sort_dredisprice_desc = "sort_dredisprice_desc",
}

export type SearchBarTypeString = `${SearchBarType}`;

export type Props = {
  searchParams: {
    k?: string; // 结果二次筛选关键 字
    sortType?: SortType;
    page?: string;
    type?: SearchBarType;
    couponId?: string;
    promotionId?: string;
    batchId?: string;
    skuId?: string;
    categoryId?: string; // 推荐用
    [key: string]: string | undefined;
  };
};
