'use client';

import React from "react";
import ReactDOM from "react-dom/client"; // 使用 createRoot API
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SuccessIcon from "@/assets/icons/SuccessIcon.svg";
import InfoIcon from "@/assets/icons/InfoIcon.svg";
import WarningIcon from "@/assets/icons/WarningIcon.svg";
import ErrorIcon from "@/assets/icons/ErrorIcon.svg";
import CircularProgress from "@mui/material/CircularProgress"; // 用于加载中的图标

const iconMap = {
  info: <InfoIcon />,
  warning: <WarningIcon />,
  success: <SuccessIcon />,
  error: <ErrorIcon />,
  loading: <CircularProgress size={20} />,
  fail: <ErrorIcon />,
};

// eslint-disable-next-line react/prop-types
const ToastComponent = ({ open, onClose, message, severity }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        icon={iconMap[severity]}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0px 4px 28px 0px #0000000A, 0px 8px 24px 0px #0000000F",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const UKToast = (() => {
  let root:any = null;
  let toasts:any = [];

  const closeToast = (id:any) => {
    toasts = toasts.filter((toast:any) => toast.id !== id);
    root.render(renderToasts());
  };

  const show = ({ content = null, icon = "info", duration = 3000 }: any) => {
    if (!root) {
      const div = document.createElement("div");
      document.body.appendChild(div);
      root = ReactDOM.createRoot(div);
    }

    const id = Date.now() + Math.floor(Math.random() * 999999999);

    toasts.push({ id, content, icon, duration });

    setTimeout(() => closeToast(id), duration);
    root.render(renderToasts());
  };

  const renderToasts = () => {
    return (
      <>
        {toasts.map((toast:any) => (
          <ToastComponent
            key={toast.id}
            open={true}
            onClose={() => closeToast(toast.id)}
            message={toast.content}
            severity={toast.icon}
          />
        ))}
      </>
    );
  };

  return { show };
})();

export default UKToast;
