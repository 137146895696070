// src/app/cms/modules/uk-pc-coupon/models/CouponStore.ts
import { types } from "mobx-state-tree";
import { ICouponInfo } from "@/app/cms/modules/uk-pc-coupon/type";

export const CmsUkCouponStore = types
  .model("CmsUkCouponStore", {
    couponListMap: types.map(types.array(types.frozen<ICouponInfo>())),
  })
  .actions((self) => ({
    setCouponInfos(listId: string, coupons: ICouponInfo[]) {
      self.couponListMap.set(listId, coupons); // 设置指定列表的优惠券信息
    },
    setCouponInfo(listId: string, updatedCoupon: ICouponInfo) {
      const couponList = self.couponListMap.get(listId);
      if (couponList) {
        const index = couponList.findIndex((coupon) => coupon.batchId === updatedCoupon.batchId);
        if (index !== -1) {
          couponList[index] = updatedCoupon; // 直接更新数组中的对象
        }
      }
    },
  }));
