'use client';
import Link, { LinkProps } from "next/link";
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'


export default function SafeLink(props: React.ComponentProps<typeof Link> & {
  trackClickParams?: {
    event_id: string; // 点击事件id
    json_param: Record<string, any>; // 点击埋点参数
  }
}) {
  const safeProps = Object.assign({}, props, { href: props.href === undefined ? "#" : props.href });
  const { trackClickParams } = safeProps || {};

  const onTrack = () => {
    if (trackClickParams) {
      const { event_id, json_param } = trackClickParams;
      eventTrackingUtils.submitCL({ event_id, json_param });
    }
  }

  return <Link {...safeProps} onClick={!trackClickParams ? (safeProps?.onClick || undefined) : onTrack}>
    {props.children}
  </Link>;
}
